$(document).on('turbolinks:load', function () {
    if (window.location.pathname.includes('competition')) {
        $('body').addClass('competition-bg')
        $('#mobile-menu').addClass('competition-bg full-width')
        $('.main-container').addClass('full-width')
    }

    $('.menuToggle').on('click', function () {
        let menu = $('#menuOverlay')
        if (menu.hasClass('slide-in')) {
            menu.removeClass('slide-in');
            menu.addClass('slide-out');
        } else if (menu.hasClass('slide-out')) {
            menu.removeClass('slide-out');
            menu.addClass('slide-in');
        } else {
            menu.addClass('slide-in');
            menu.removeClass('d-none')
        }
    })

    $(`.nav-pages-link[href="${window.location.pathname}"]`).addClass('active')

    let popup = $('.main-page-overlay')
    $('.link-green h2').on('click', function (e) {
        popup.removeClass('d-none');
        popup.toggleClass('slide-down');
        popup.removeClass('slide-up')
        $('body').addClass('popup-overflow')
        if ($(e.target).hasClass('scroll-down')) {
            setTimeout(function () {
                document.querySelector('.popup-text').scroll({
                    top: 1000,
                    behavior: 'smooth'
                })
            }, 200)
        }
    })

    $('#popupClose').on('click', function () {
        popup.addClass('slide-up');
        popup.removeClass('slide-down');
        popup.addClass('d-none')
        $('body').removeClass('popup-overflow')
    })

    let commentsIds = []
    document.querySelectorAll('.comment').forEach((e) => commentsIds.push(e.id))

    $('.next-comment').on('click', function () {
        let activeComment = $('.comment.active-comment')
        activeComment.removeClass('comment-slide-in')
        activeComment.addClass('comment-slide-out')

        let nextComment
        let nextCommentId = commentsIds.indexOf(activeComment.attr('id')) + 1
        if (nextCommentId === commentsIds.length) {
            nextComment = $(`.comment`).first();
        } else {
            nextComment = $(`#${commentsIds[nextCommentId]}`)
        }

        setTimeout(function () {
            activeComment.removeClass('active-comment')
            activeComment.removeClass('comment-slide-out')

            nextComment.addClass('active-comment')
            nextComment.addClass('comment-slide-in')
        }, 200);

    })

    for (let i = 1; i < 10000; i++) {
        let row = document.getElementById(`listRow${i}`)
        if (row == null) {
            break
        }
        let lists = document.querySelectorAll(`#list${i}`)
        lists.forEach(function (el) {
            row.appendChild(el)
        })
    }

    // Make all links in trix-content target: blank
    document.querySelectorAll('.trix-content a').forEach(function (x) {
        x.setAttribute('target', '_blank')
    })

    // No scroll text areas. Expanding down.
    $('textarea').each(function () {
        this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
    }).on('input', function () {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 'px';
    });

    $("#contact_images").change(function(){
        let files = document.querySelector('#contact_images').files
        let ul = $('#uploadedFiles')
        document.querySelectorAll('#uploadedFiles li').forEach(e => e.remove());
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            ul.append(`<li>${file.name}</li>`)
        }
    });

    $(".deleteFile").on('click', function(){
        let fileName = $(this).parent.find('.file-name').text
        document.querySelector('#contact_images').files
    })
})